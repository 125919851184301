import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  mintWarg,
  mintPixaNFT,
  tokenURIfinder,
  checkPixaBalance,
  mintThreeWarg,
  checkWargsMinted,
  claimVortical,
  claimVorticalLoop,
  upgradeGem,
  checkDopplers
} from "./utils/interact.js";
import pixagif from 'D:/NFT_development/front_end/new-app-pages/limited-editions/nft-minter-tutorial-main/minter-starter-files/src/pixa-logo.gif'
import pixapng from 'D:/NFT_development/front_end/new-app-pages/limited-editions/nft-minter-tutorial-main/minter-starter-files/src/pixa-logo-2.png'
import onegif from 'D:/NFT_development/front_end/new-app-pages/limited-editions/nft-minter-tutorial-main/minter-starter-files/src/1.gif'
import twogif from 'D:/NFT_development/front_end/new-app-pages/limited-editions/nft-minter-tutorial-main/minter-starter-files/src/2.gif'
import threegif from 'D:/NFT_development/front_end/new-app-pages/limited-editions/nft-minter-tutorial-main/minter-starter-files/src/3.gif'
import fivegif from 'D:/NFT_development/front_end/new-app-pages/limited-editions/nft-minter-tutorial-main/minter-starter-files/src/5.gif'
import sixgif from 'D:/NFT_development/front_end/new-app-pages/limited-editions/nft-minter-tutorial-main/minter-starter-files/src/6.gif'
import sevengif from 'D:/NFT_development/front_end/new-app-pages/limited-editions/nft-minter-tutorial-main/minter-starter-files/src/7.gif'
import eightgif from 'D:/NFT_development/front_end/new-app-pages/limited-editions/nft-minter-tutorial-main/minter-starter-files/src/8.gif'
import tengif from 'D:/NFT_development/front_end/new-app-pages/limited-editions/nft-minter-tutorial-main/minter-starter-files/src/10.gif'
import sabulogif from 'D:/NFT_development/front_end/new-app-pages/limited-editions/nft-minter-tutorial-main/minter-starter-files/src/10011.png'
import auctiongif from 'D:/NFT_development/front_end/new-app-pages/limited-editions/nft-minter-tutorial-main/minter-starter-files/src/auction.PNG'
import pleasewait from 'D:/NFT_development/front_end/new-app-pages/limited-editions/nft-minter-tutorial-main/minter-starter-files/src/pleasewait1.PNG'

const Minter = (props) => {

  function addWalletListener() {
  if (window.ethereum) {
    window.ethereum.on("accountsChanged", (accounts) => {
      if (accounts.length > 0) {
        setWallet(accounts[0]);
        setStatus("👆🏽 Write a message in the text-field above.");
      } else {
        setWallet("");
        setStatus("🦊 Connect to Metamask using the top button.");
      }
    });
  } else {
    setStatus(
      <p>
        {" "}
        🦊{" "}
        <a target="_blank" href={`https://metamask.io/download.html`}>
          You must install Metamask, a virtual Ethereum wallet, in your
          browser.
        </a>
      </p>
    );
    }
  }

  //State variables
  const [ownership, setAreYouOwner] = useState("");
  const [upgradestatus, setupgradeStatus] = useState("");
  const [pixaBalance, setPixaBalance] = useState("");
  const [walletAddress, setWallet] = useState("");
  const [wargs, setWargsMinted] = useState("");
  const [displayRank, setRank] = useState("");
  const [tokenID, setToken] = useState("");
  const [tokenURI, setTokenURI] = useState("");
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");
  const [wizstatus, setWizStatus] = useState(""); 
  useEffect(async () => { //TODO: implement
    const {address, status} = await getCurrentWalletConnected();
    const {currentwargsminted} = await checkWargsMinted();
    setWallet(address);
    setStatus(status);
    setWargsMinted(currentwargsminted)
    setTokenURI("fff");
    setupgradeStatus("currently selected.")    
    const {walletpixabalance} = await checkPixaBalance(address)
    setPixaBalance(walletpixabalance)
    addWalletListener(); 
  }, []);


  const onTokenChange = async (num) => {
    setToken(num)
    setAreYouOwner("")
    setTokenURI(pleasewait)
    setRank("") 
    setAreYouOwner("please wait... ⏱️") 
    const { tokenwiz} = await tokenURIfinder(num, walletAddress);   
    setTokenURI("https://storage.googleapis.com/pixawyverns/" + tokenwiz + "PWYV.gif")
    setRank(displayRank)
    setAreYouOwner(ownership)
  };


  const connectWalletPressed = async () => { //TODO: implement
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const onMintWargPressed = async () => { //TODO: implement
      const { status } = await mintWarg(); 
  };

  const onMintThreeWargPressed = async () => { //TODO: implement
      const { status } = await mintThreeWarg();
  };

  const onMintWargPixaPressed = async () => { //TODO: implement
      const { status } = await mintPixaNFT(walletAddress); 
  };

  const onClaimPressed = async () => { //TODO: implement
      const { status } = await claimVortical();
  };

  const onClaimLoopPressed = async () => { //TODO: implement
      const { status } = await claimVorticalLoop();
  };

  const onCheckPressed = async () => { //TODO: implement
      const { wizstatus } = await checkDopplers(name);
    setWizStatus(wizstatus);  
  };

  const onUpgrade1Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 1);
  };

  const onUpgrade2Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 2);
  };

  const onUpgrade3Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 3);
  };

  const onUpgrade4Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 4);
  };

  const onUpgrade5Pressed = async () => { //TODO: implement
    setupgradeStatus("upgrading...");  
    const { status } = await upgradeGem(walletAddress, tokenID, 5);
  };


  
  return (
    <div className="Minter">
<div id="stars"></div>
<div id="stars2"></div>
<div id="stars3"></div>

<h5>By using this webpage you agree to the terms & conditions outlined at the bottom of this page. Disclaimer: Use this site at your own risk.</h5>       

<h1>
          <div>
      <img src={pixapng} alt="Image" height={45} width={100} border={0}/>
      </div>
</h1>
      <h2> <a href="https://wizards.pixanft.com" rel="noreferrer">  🧙‍♂️</a>  <a href="https://witches.pixanft.com" rel="noreferrer">  🧙‍♀️</a> <a href="https://wyverns.pixanft.com" rel="noreferrer">  🐲</a> <a href="https://wargs.pixanft.com" rel="noreferrer">  🐺</a>  <a href="https://brews.pixanft.com" rel="noreferrer">  🍺</a> <a href="https://limitededitions.pixanft.com" rel="noreferrer">  🖼️</a> </h2>
      <h5>Click an emoji to navigate to other pages!</h5>         
      <h2> <a href="https://tokens.pixanft.com" rel="noreferrer">  💰</a>  <a href="https://relics.pixanft.com" rel="noreferrer">  🗡️</a> <a href="https://wizardao.pixanft.com" rel="noreferrer">  🎩</a> <a href="https://tomes.pixanft.com" rel="noreferrer">  📖</a> <a href="https://land.pixanft.com" rel="noreferrer">  🏡</a> <a href="https://badges.pixanft.com" rel="noreferrer">  📛</a> </h2>
           
      <button id="walletButton" onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Connect Wallet</span>
        )}
      </button>


      <h1 >🖼️  PixaLE  🖼️ </h1>
      <h1>_________</h1>
      
<h4>The PixaLE collection is a rare and highly coveted set of 1/1 pixel artworks that depict various events and locations within the PixaRealm. These unique pieces of digital art are prized by collectors for their intricate details and nostalgicically pixelated style. Each PixaLE artwork is a one-of-a-kind creation, making them a truly special addition to any collection. Whether you're a seasoned collector of digital art or just looking to add a touch of magic to your life, the PixaLE collection is sure to delight and inspire. So don't miss your chance to own a piece of the PixaRealm's history – mint your own PixaLE today!</h4>

<h4>The PixaLE NFTs are ERC721 tokens minted on the Ethereum blockchain. They have been released periodically since March 2021. For more information about the other NFT collections that exist in the PixaRealm, visit our <a href="https://pixanft.com" rel="noreferrer">  website</a></h4>

<h4> Scroll down to mint a PixaLE of your own, or check out the collection on <a href="https://opensea.io/collection/pixale" rel="noreferrer">  OpenSea</a></h4>

<h1>_________</h1>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>

<h1>Mint </h1>
<h1>_________</h1>
<h4>The PixaLE collection NFTs are released periodically and are auctioned on this webpage. Follow us on twitter and join the discord to keep track of the next PixaLE release!</h4>
<h4>ENSURE WALLET IS CONNECTED TO ETHEREUM</h4>
<h1>
<div>
<img id="image" src={auctiongif} alt="Image" height={220} width={250} border={10}/>
</div>
</h1>

<button id="mintButton" >
        AUCTION CLOSED
</button>

<h1>_________</h1>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>

<h1>Browse </h1>
<h1>_________</h1>
<h4>All the PixaLE releases so far are shown below with links to the OpenSea page.</h4>

<br></br>
<br></br>

<h2>"Sabulo"</h2>
<h1>
<div>
<img id="image" src={sabulogif} alt="Image" height={190} width={250} border={10}/>
</div>
</h1>
<h3><a href="https://opensea.io/assets/ethereum/0x6b6b77c3ac72c76fb7f57444782869de427a51b5/10011" rel="noreferrer"> OpenSea</a></h3>

<br></br>
<h4>___________________</h4>
<br></br>
<br></br>


<h2>"PixaRun"</h2>
<h1>
<div>
<img id="image" src={tengif} alt="Image" height={150} width={250} border={10}/>
</div>
</h1>
<h3><a href="https://opensea.io/assets/ethereum/0x6b6b77c3ac72c76fb7f57444782869de427a51b5/10" rel="noreferrer"> OpenSea</a></h3>

<br></br>
<h4>___________________</h4>
<br></br>
<br></br>
  
<h2>"A Bruma Snooze"</h2>
<h1>
<div>
<img id="image" src={eightgif} alt="Image" height={220} width={250} border={10}/>
</div>
</h1>
<h3><a href="https://opensea.io/assets/ethereum/0x6b6b77c3ac72c76fb7f57444782869de427a51b5/8" rel="noreferrer"> OpenSea</a></h3>

<br></br>
<h4>___________________</h4>
<br></br>
<br></br>


<h2>"Mystical Montem Ride"</h2>
<h1>
<div>
<img id="image" src={sevengif} alt="Image" height={220} width={250} border={10}/>
</div>
</h1>
<h3><a href="https://opensea.io/assets/ethereum/0x6b6b77c3ac72c76fb7f57444782869de427a51b5/7" rel="noreferrer"> OpenSea</a></h3>

<br></br>
<h4>___________________</h4>
<br></br>
<br></br>

<h2>"Sabulo Seaside Surprise"</h2>
<h1>
<div>
<img id="image" src={sixgif} alt="Image" height={220} width={250} border={10}/>
</div>
</h1>
<h3><a href="https://opensea.io/assets/ethereum/0x6b6b77c3ac72c76fb7f57444782869de427a51b5/6" rel="noreferrer"> OpenSea</a></h3>

<br></br>
<h4>___________________</h4>
<br></br>
<br></br>

<h2>"Camping on Jangala"</h2>
<h1>
<div>
<img id="image" src={fivegif} alt="Image" height={220} width={250} border={10}/>
</div>
</h1>
<h3><a href="https://opensea.io/assets/ethereum/0x6b6b77c3ac72c76fb7f57444782869de427a51b5/5" rel="noreferrer"> OpenSea</a></h3>

<br></br>
<h4>___________________</h4>
<br></br>
<br></br>



<h2>"Aqua Treasure Trove"</h2>
<h1>
<div>
<img id="image" src={threegif} alt="Image" height={220} width={250} border={10}/>
</div>
</h1>
<h3><a href="https://opensea.io/assets/ethereum/0x6b6b77c3ac72c76fb7f57444782869de427a51b5/3" rel="noreferrer"> OpenSea</a></h3>

<br></br>
<h4>___________________</h4>
<br></br>
<br></br>



<h2>"The PixaVerse"</h2>
<h1>
<div>
<img id="image" src={twogif} alt="Image" height={220} width={250} border={10}/>
</div>
</h1>
<h3><a href="https://opensea.io/assets/ethereum/0x6b6b77c3ac72c76fb7f57444782869de427a51b5/2" rel="noreferrer"> OpenSea</a></h3>

<br></br>
<h4>___________________</h4>
<br></br>
<br></br>

<h2>"Terra Nights"</h2>
<h1>
<div>
<img id="image" src={onegif} alt="Image" height={220} width={250} border={10}/>
</div>
</h1>
<h3><a href="https://opensea.io/assets/ethereum/0x6b6b77c3ac72c76fb7f57444782869de427a51b5/1" rel="noreferrer"> OpenSea</a></h3>

<br></br>
<h1>_________</h1>
<br></br>
<br></br>



<br></br>


<br></br>
<br></br>
<br></br>
<br></br>
<h3>Important Links:</h3>

<h4><a href="https://etherscan.io/address/0x6b6b77c3ac72c76fb7f57444782869de427a51b5" rel="noreferrer">  PixaLE Contract</a> </h4>

<h4><a href="https://linktr.ee/pixanft" rel="noreferrer">  Linktree</a> </h4>

<h4> <a href="https://opensea.io/PixaDeployer?tab=created" rel="noreferrer">  OpenSea Collections</a> </h4>

<h4><a href="https://discord.gg/pixa" rel="noreferrer">  Discord</a> </h4>

<h4><a href="https://twitter.com/pixa_nft" rel="noreferrer">  Twitter </a></h4>

<br></br>


<h3>Terms & Conditions:</h3>

<h9>(applies to all Pixa sites & smart contracts) - Use this site at your own risk. Pixa is not liable for any losses from the use of this site or associated smart contracts. Your purchase of Pixa NFTs does NOT CONSTITUTE AN INVESTMENT. Purchase of these NFTs from Pixa is meant for entertainment purposes only. Pixa makes no warranties regarding future value, express or implied, to the public or to buyers. Pixa does not offer refunds on purchased NFTs. Only purchase if you wish to own the artwork and nothing else. Only purchase with the current state of the project in mind and the assumption that no future value will be delivered. Pixa NFTs and $PIXA tokens are not currency and do not convey ownership in Pixa or its assets. You are purchasing a digital collectible, we do not guarantee any future development around these NFTs. $PIXA tokens are not shares or securities of any type. Holding a token does not entitle the holder to any ownership or other interest in Pixa (formerly known as PixaLyfe), future business operations, future revenue, or any control or ability to bind the entity. $PIXA tokens are merely a means by which you may be able to utilize certain services such as changing aspects of the artwork in your Pixa NFT. There is no guarantee that the PixaRealm platform will actually be developed, although we guarantee that it is our intention to do so.</h9>

<h1>
          <div>
      <img src={pixagif} alt="Image" height={200} width={200} border={0}/>
      </div>
</h1>
    </div>
  );
};



export default Minter;
